import React, { useState, useEffect } from 'react'
import AuthContext, { type User } from '../../contexts/authContext'
import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import MessageModal from '../message_modal/messageModal'
import { pca } from '../../hooks/useAzureLogin'
import { type AuthenticationResult } from '@azure/msal-browser'

const AuthProvider = ({ children }: any): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<User>({})
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')

  const showModal = (message: string): void => {
    setModalMessage(message)
    setModalOpen(true)
  }

  useEffect(() => {
    const accountInfo = pca.getAllAccounts()[0]
    if (accountInfo) {
      const usernameOrEmail: any = accountInfo.username || accountInfo.idTokenClaims?.email
      // Set this value to your state/context
      console.log('setting user', { usernameOrEmail })
      if (usernameOrEmail !== currentUser.username) {
        setCurrentUser(prevUser => ({ ...prevUser, username: usernameOrEmail }))
      }
    }
  }, [currentUser])

  const getTokens = async (): Promise<AuthenticationResult | null> => {
    await pca.initialize()
    const request = {
      scopes: ['openid', 'profile', 'email', 'Chat.Read', 'Chat.ReadBasic', 'User.Read'],
      account: pca.getAllAccounts()[0] // Assuming the first account is the current user
    }

    try {
      const response = await pca.acquireTokenSilent(request)
      return response
    } catch (error) {
      // Handle token acquisition errors
      console.error(error)
      return null
    }
  }

  const authenticatedRequest = async (requestConfig: AxiosRequestConfig): Promise<AxiosResponse<any> | undefined> => {
    try {
      const tokens = await getTokens()
      if (!tokens) {
        throw new Error('Failed to retrieve tokens')
      }

      console.log('[authenticated request] ', { currentUser, requestConfig, tokens })
      const response = await axios({
        ...requestConfig,
        headers: {
          accessToken: `Bearer ${tokens.accessToken}`,
          idToken: tokens.idToken
        }
      })

      return response
    } catch (error: any) {
      console.log({ error })
      showModal(error.response.data.message)
    }
  }

  const updateNumberOfChatsExported = (newCount: number): void => {
    console.log('inside updateNumberOfChatsExported()', { currentUser })
    const updatedUser = {
      userTier: currentUser.userTier,
      name: currentUser.name,
      username: currentUser.username,
      numberOfChatsExported: newCount
    }

    console.log('Updated user:', updatedUser)

    setCurrentUser(updatedUser)
  }

  const value = {
    currentUser,
    setUser: setCurrentUser,
    authenticatedRequest,
    updateNumberOfChatsExported
  }

  return (
    <AuthContext.Provider value={value}>
      <MessageModal open={modalOpen} message={modalMessage} onClose={() => { setModalOpen(false) }} />
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
