/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import {
  Typography,
  Divider,
  Box,
  Accordion,
  AccordionSummary,
  Grid,
  InputBase
} from '@mui/material'
import Loader from 'react-loader-spinner'
import { useAuth } from '../../contexts/authContext'
import { TEAMS_EXPORTER_API_BASE_URL } from '../../config'
import RemoveSubscriptionButton from '../remove_subscription/RemoveSubscriptionButton'
import { LoadingButton } from '@mui/lab'

const AdminPage: React.FC = () => {
  const { authenticatedRequest } = useAuth()
  const [premiumTenantUsers, setPremiumTenantUsers] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [newMemberEmail, setNewMemberEmail] = useState<string>('')
  const [licensesInUse, setLicensesInUse] = useState<number>(0)
  const [purchasedLicenses, setpurchasedLicenses] = useState<number>(0)

  useEffect(() => {
    void fetchPremiumTenantUsers()
  }, [])

  const fetchPremiumTenantUsers = async (): Promise<void> => {
    setLoading(true)
    try {
      const response = await authenticatedRequest({
        method: 'get',
        url: `${TEAMS_EXPORTER_API_BASE_URL}/admin/list-subscriptions`
      })
      const {
        data: { premiumTenantUsers: premiumTenantUsersInDynamo, licensesInUse, purchasedLicenses }
      } = response
      setPremiumTenantUsers(premiumTenantUsersInDynamo)
      setLicensesInUse(licensesInUse)
      setpurchasedLicenses(purchasedLicenses)
    } catch (err: any) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const addPremiumMember = async (): Promise<void> => {
    setLoading(true)
    try {
      await authenticatedRequest({
        method: 'post',
        url: `${TEAMS_EXPORTER_API_BASE_URL}/admin/upgrade-user/${newMemberEmail}`
      })
      await fetchPremiumTenantUsers()
      setNewMemberEmail('')
    } catch (err: any) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleUsersUpdate = (updatedUsers: string[]): void => {
    setPremiumTenantUsers(updatedUsers)
    setLicensesInUse(licensesInUse - 1)
  }

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Loader type="ThreeDots" color="#6666FF" height={80} width={80} />
        <Typography variant="body2">Loading...</Typography>
      </Box>
    )
  }

  return (
    <Box p={6}>
      <Typography variant="h5" gutterBottom>
        {`Premium Tenant Users (${licensesInUse} / ${purchasedLicenses})`}
      </Typography>
      <Divider style={{ marginBottom: '20px' }} />
      <Grid container spacing={2}>
        {premiumTenantUsers.map((user, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                aria-controls={`panel-content-${index}`}
                id={`panel-header-${index}`}
              >
                <Typography
                  sx={{ flex: 1, fontWeight: 500, display: 'flex', alignItems: 'center' }}
                >
                  {user}
                </Typography>
                <RemoveSubscriptionButton
                  username={user}
                  onUsersUpdate={handleUsersUpdate}
                />
              </AccordionSummary>
            </Accordion>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary >
              <InputBase
                placeholder="Enter user email address"
                value={newMemberEmail}
                onChange={(e) => { setNewMemberEmail(e.target.value) }}
                sx={{
                  flex: 1,
                  fontWeight: 500,
                  backgroundColor: 'transparent',
                  '&:focus': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                  }
                }}
                onClick={(e) => { e.stopPropagation() }}
              />
              <LoadingButton
                loading={loading}
                variant="outlined"
                color="success"
                onClick={addPremiumMember}
              >
                Enable Premium Access
              </LoadingButton>
            </AccordionSummary>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AdminPage
