import React from 'react'
import { Menu, MenuItem, Typography } from '@mui/material'

interface NavMenuProps {
  isOpen: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
  pages: Array<{ name: string, path: string }>
  navigateToPage: (path: string) => void
}

export const NavMenu: React.FC<NavMenuProps> = ({ isOpen, anchorEl, onClose, pages, navigateToPage }) => {
  return (
      <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          open={isOpen}
          onClose={onClose}
          sx={{
            display: { xs: 'block', md: 'none' }
          }}
      >
          {pages.map(({ name, path }) => (
              <MenuItem key={name} onClick={() => { navigateToPage(path) }}>
                  <Typography textAlign="center" sx={{ fontFamily: 'Inter' }}>
                      {name}
                  </Typography>
              </MenuItem>
          ))}
      </Menu>
  )
}
