import React from 'react'
import moment from 'moment'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Box } from '@mui/material'

interface DateRangePickerProps {
  startDate: Date | null
  endDate: Date | null
  onStartDateChange: (date: Date | null) => void
  onEndDateChange: (date: Date | null) => void
  earliestDate: string
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  earliestDate
}) => {
  const minDateValue = moment(earliestDate)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label="Start Date"
        value={moment(startDate)}
        onChange={(date) => { onStartDateChange(date ? date.toDate() : null) }}
        minDate={minDateValue}
      />
      <Box mt={2} ></Box>
      <DatePicker
        label="End Date"
        value={moment(endDate)}
        onChange={(date) => { onEndDateChange(date ? date.toDate() : null) }}
        minDate={minDateValue}
      />
      <Box mt={2} ></Box>
    </LocalizationProvider>
  )
}
