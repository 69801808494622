/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Button, Typography, List, ListItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TEAMS_EXPORTER_API_BASE_URL } from '../../config'
import { useAuth } from '../../contexts/authContext'
import ExportButton from '../export_button/ExportButton'
import Loader from 'react-loader-spinner'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import RefreshIcon from '@mui/icons-material/Refresh'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Modal from '@mui/material/Modal'
import { DateRangePicker } from '../date_range_picker/DateRangePicker'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import SearchBar from '../search_bar/SearchBar'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

interface Chat {
  id: string
  topic: string
  webUrl: string
  members: any[]
  chatType: string
  displayTopic: string
  createdDateTime: string
}

const ListChats: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const { authenticatedRequest, setUser, currentUser } = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [dates, setDates] = useState<Record<string, { startDate: Date | null, endDate: Date | null, isDatePickerOpen: boolean }>>({})
  const [chatTypeFilter, setChatTypeFilter] = useState<string>('all')

  // If the current user changes, update the display topics
  useEffect(() => {
    if (chats.length > 0) {
      const chatsWithUpdatedDisplayTopic = chats.map((chat: Chat) => ({
        ...chat,
        displayTopic: getDisplayTopic(chat)
      }))
      setChats(chatsWithUpdatedDisplayTopic)
    }
  }, [currentUser])

  const handleOpenDatePicker = (chatId: string): void => {
    setDates(prev => ({
      ...prev,
      [chatId]: {
        ...prev[chatId],
        isDatePickerOpen: true
      }
    }))
  }

  const handleCloseDatePicker = (chatId: string): void => {
    setDates(prev => ({
      ...prev,
      [chatId]: {
        ...prev[chatId],
        isDatePickerOpen: false
      }
    }))
  }

  const getDisplayTopic = (chat: Chat): string => {
    if (chat.chatType === 'oneOnOne' && chat.members.length === 2) {
      return chat.members[0].displayName === currentUser.name
        ? chat.members[1].displayName
        : chat.members[0].displayName
    } else {
      return chat.topic || 'Unnamed Chat'
    }
  }

  useEffect(() => {
    fetchChats()
  }, [])

  const fetchChats = async (): Promise<void> => {
    setLoading(true)
    setError(null)
    try {
      const response = await authenticatedRequest({
        method: 'get',
        url: `${TEAMS_EXPORTER_API_BASE_URL}/list-chats`
      })

      if (!response?.data.chats) {
        throw new Error('No chats found')
      }

      const chatsWithDisplayTopic = response.data.chats.map((chat: Chat) => ({
        ...chat,
        displayTopic: getDisplayTopic(chat)
      }))
      setChats(chatsWithDisplayTopic)

      // Update the user only if necessary
      if (currentUser.userTier !== response?.data.userTier || currentUser.name !== response?.data.name) {
        setUser({
          ...currentUser,
          userTier: response?.data.userTier,
          name: response?.data.name,
          numberOfChatsExported: response?.data.numberOfChatsExported
        })
      }
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false) // Ensure loading is set to false in either case (success/failure)
    }
  }

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <Loader type="ThreeDots" color="#6666FF" height={80} width={80} />
        <Typography variant="body2">Loading chats...</Typography>
      </Box>
    )
  }

  if (error) {
    return (
      <Alert severity="error">
        Error: {error}
      </Alert>
    )
  }

  let displayedChats = chats
  if (searchTerm.startsWith('users:')) {
    const userNames = searchTerm
      .slice(6)
      .split(',')
      .map(name => name.trim().toLowerCase())

    displayedChats = displayedChats.filter(chat =>
      userNames.every(userName =>
        chat.members.some(member =>
          member.displayName.toLowerCase().includes(userName)
        )
      )
    )
  } else if (searchTerm.trim() !== '') { // Check if searchTerm is not only whitespace
    // Normalize search term
    const normalizedSearchTerm = searchTerm.trim().toLowerCase()

    // Filter chats based on chat name
    displayedChats = displayedChats.filter(chat =>
      chat.displayTopic.toLowerCase().includes(normalizedSearchTerm)
    )
  }

  if (chatTypeFilter === 'oneOnOne') {
    displayedChats = displayedChats.filter(chat => chat.chatType === 'oneOnOne')
  } else if (chatTypeFilter === 'group') {
    displayedChats = displayedChats.filter(chat => chat.chatType !== 'oneOnOne')
  }

  return (
    <Box p={6}> {/* Add padding */}
      {chats.length > 0
        ? (
        <>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <ToggleButtonGroup
            value={chatTypeFilter}
            exclusive
            onChange={(event, newValue) => setChatTypeFilter(newValue)}
            aria-label="chat type"
          >
            <ToggleButton value="all" aria-label="all chats">
              All
            </ToggleButton>
            <ToggleButton value="oneOnOne" aria-label="one on one chats">
              1:1
            </ToggleButton>
            <ToggleButton value="group" aria-label="group chats">
              Group
            </ToggleButton>
          </ToggleButtonGroup>
          <Box mt={3}>
              {displayedChats
                .map(chat => {
                  return (
                    <Box key={chat.id} mb={2} boxShadow={2} borderRadius={2} overflow="hidden">
                    <Accordion>
                      <AccordionSummary
                        aria-controls={`content-${chat.id}`}
                        id={`header-${chat.id}`}
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          {/* Add the chat type text */}
                          <Typography variant="body2" color="textSecondary" sx={{ width: '50px', textAlign: 'center', marginRight: '2px', marginLeft: '-7px', fontWeight: 600 }}>
                            {chat.chatType === 'oneOnOne' ? '1:1' : 'Group'}
                          </Typography>

                          {/* Add a vertical separator */}
                          <Box borderRight="1px solid #e0e0e0" height="100%" marginRight={2} sx={{ marginLeft: '7px' }} />

                          {/* Existing chat topic and link */}
                          <Typography
                            flex="1"
                            component="div"
                            sx={{
                              '& a': {
                                color: '#6666FF',
                                textDecoration: 'none',
                                fontWeight: 600,
                                '&:hover': {
                                  transform: 'scale(2.05)'
                                },
                                '&:visited': {
                                  color: '#6666FF'
                                }
                              }
                            }}
                          >
                            <a
                              href={chat.webUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {chat.displayTopic}
                              <OpenInNewIcon
                                sx={{
                                  fontSize: '1rem',
                                  marginLeft: '0.5rem'
                                }}
                              />
                            </a>
                          </Typography>
                            <Box display="flex" alignItems="center">
                            <Box marginRight={chat.chatType !== 'oneOnOne' ? 1 : 4}>
                              <Button onClick={(e) => {
                                handleOpenDatePicker(chat.id)
                                e.stopPropagation()
                              }}>
                                <CalendarMonthIcon sx={{ color: '#A1A1A1' }}/>
                              </Button>
                              <Modal
                                onClick={e => e.stopPropagation()}
                                open={dates[chat.id]?.isDatePickerOpen || false}
                                onClose={() => handleCloseDatePicker(chat.id)}
                                BackdropProps={{
                                  style: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.15)' // 0.5 is the opacity, you can adjust it to your preference
                                  }
                                }}
                              >
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 300,
                                    bgcolor: 'background.paper',
                                    p: 4,
                                    borderRadius: '5px'
                                  }}
                                  onClick={e => e.stopPropagation()}
                                >
                                  <DateRangePicker
                                    startDate={dates[chat.id]?.startDate ?? null}
                                    endDate={dates[chat.id]?.endDate ?? null}
                                    onStartDateChange={(date) => {
                                      setDates(prev => ({
                                        ...prev,
                                        [chat.id]: {
                                          ...prev[chat.id],
                                          startDate: date
                                        }
                                      }))
                                    }}
                                    onEndDateChange={(date) => {
                                      setDates(prev => ({
                                        ...prev,
                                        [chat.id]: {
                                          ...prev[chat.id],
                                          endDate: date
                                        }
                                      }))
                                    }}
                                    earliestDate={chat.createdDateTime}
                                  />
                                  <ExportButton chatId={chat.id} chatTopic={chat.displayTopic} startDate={dates[chat.id]?.startDate ?? null} endDate={dates[chat.id]?.endDate ?? null} />
                                </Box>
                              </Modal>
                              <ExportButton chatId={chat.id} chatTopic={chat.displayTopic} startDate={dates[chat.id]?.startDate ?? null} endDate={dates[chat.id]?.endDate ?? null} />
                            </Box>
                              {chat.chatType !== 'oneOnOne' && <ExpandMoreIcon />}
                              </Box>
                          </Box>
                        </AccordionSummary>
                        {chat.chatType !== 'oneOnOne' && (
                          <AccordionDetails>
                              <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 600 }}>
                                Members:
                              <List>
                                {chat.members.map((member, index) => (
                                  <ListItem key={index}>
                                    <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 600 }}>
                                      {member.displayName}
                                    </Typography>
                                  </ListItem>
                                ))}
                              </List>
                            </Typography>
                          </AccordionDetails>
                        )}
                      </Accordion>
                    </Box>
                  )
                })}
            </Box>
          </>
          )
        : (
          <Typography variant="body2" gutterBottom>
            No chats fetched yet. Click the button below to fetch.
          </Typography>
          )}
      <Box mt={3}>
        <Button variant="contained" color="primary" sx={{ backgroundColor: '#6666FF' }} onClick={fetchChats}>
          <RefreshIcon />
        </Button>
      </Box>
    </Box>
  )
}

export default ListChats
