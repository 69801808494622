/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { loadStripe, type Stripe } from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js'
import { Box, Button, CircularProgress, Divider, Paper, TextField, Typography } from '@mui/material'
import { TEAMS_EXPORTER_API_BASE_URL, STRIPE_PUBLISHABLE_KEY } from '../../config'
import DownloadIcon from '@mui/icons-material/Download'
import ScheduleIcon from '@mui/icons-material/Schedule'
import DateRangeIcon from '@mui/icons-material/DateRange'
import EmailIcon from '@mui/icons-material/Email'
import { useAuth } from '../../contexts/authContext'
import { SubscriptionType } from '../../types/SubscriptionType'

export const stripePromise: Promise<Stripe | null> = loadStripe(STRIPE_PUBLISHABLE_KEY)

const PaymentForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const stripe = useStripe()
  const { authenticatedRequest } = useAuth()
  const [numberOfLicenses, setNumberOfLicenses] = useState(1) // default to 1 license

  const calculateCorporatePrice = () => {
    return (numberOfLicenses * 4.99).toFixed(2)
  }

  const handleCorporatePayment = async (): Promise<void> => {
    if (!stripe) {
      return
    }

    setLoading(true)

    // Step 1: Create Checkout Session on the backend
    const response = await authenticatedRequest({
      method: 'post',
      url: `${TEAMS_EXPORTER_API_BASE_URL}/payment`,
      data: {
        subscriptionType: SubscriptionType.CORPORATE,
        quantity: numberOfLicenses
      }
    })

    console.log('response from authenticated request: ', response)

    const data = response?.data
    const sessionId: string = data.sessionId

    // Step 2: Confirm the payment on the frontend
    const { error } = await stripe.redirectToCheckout({
      sessionId
    })

    if (error) {
      console.error(error.message)
    }

    setLoading(false)
  }

  return (
    <>
      <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: '20px auto' }}>
        {/* Tabs for Individual and Corporate */}
          <>
            <Typography variant='h6'>
              Teams Exporter Premium
            </Typography>
            {/* Price Display */}
            <Typography variant="h6" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
              $4.99/month
            </Typography>
            {/* ... and so on for the rest of your individual offer JSX */}
            <Divider style={{ marginBottom: '20px' }} />

            <Box display="flex" alignItems="center" marginBottom={2}>
              <DownloadIcon style={{ marginRight: '10px', fontSize: '2rem', color: '#6666FF' }} />
              <Typography variant="h6">
                Get Unlimited Chat Exports
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px' }}>
              Unlock the ability to export all your chats without any limits. Perfect for businesses, analysts, and data enthusiasts.
            </Typography>

            <Box display="flex" alignItems="center" marginBottom={2}>
              <DateRangeIcon style={{ marginRight: '10px', fontSize: '2rem', color: '#6666FF' }} />
              <Typography variant="h6">Selective Date Ranges</Typography>
            </Box>
            <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px' }}>
              Need data from a specific time frame? Premium users can select exact date ranges to export chats from and to, ensuring precision in their reports.
            </Typography>

            <Box display="flex" alignItems="center" marginBottom={2} sx={{ opacity: 0.5 }}>
              <ScheduleIcon style={{ marginRight: '10px', fontSize: '2rem', color: '#6666FF' }} />
              <Typography variant="h6">
                Export Scheduling
                <Typography variant="caption" color="textSecondary" component="span" style={{ marginLeft: '5px' }}>
                  (Coming Soon)
                </Typography>
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px' }}>
              Once your chat exports are ready, we'll send them directly to your inbox in PDF format. Instant access, wherever you are!
            </Typography>

            <Box display="flex" alignItems="center" marginBottom={2} sx={{ opacity: 0.5 }}>
              <EmailIcon style={{ marginRight: '10px', fontSize: '2rem', color: '#6666FF' }} />
              <Typography variant="h6">
                Email Delivery as PDF
                <Typography variant="caption" color="textSecondary" component="span" style={{ marginLeft: '5px' }}>
                  (Coming Soon)
                </Typography>
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary" style={{ marginBottom: '20px' }}>
              Set up schedules to automatically export chats on your behalf on a recurring basis. Keep your data up-to-date without lifting a finger!
            </Typography>
            <Divider style={{ marginBottom: '20px' }} />
            <Typography variant="body2" style={{ marginBottom: '20px' }}>
              Unlock premium features for your entire team at a discounted rate of $4.99/user/month.
            </Typography>
            <TextField
              label="Number of Licenses"
              type="number"
              value={numberOfLicenses}
              onChange={(e) => setNumberOfLicenses(Math.max(1, parseInt(e.target.value)))} // Ensure minimum 1 license
              style={{ marginBottom: '20px' }}
            />
            <Typography variant="body2" style={{ marginBottom: '20px' }}>
              Total Price: ${calculateCorporatePrice()}/month
            </Typography>
            {/* Re-use your existing JSX components for features like chat exports, date range, etc. */}
            {/* ... */}
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCorporatePayment}
                disabled={loading}
                endIcon={loading ? <CircularProgress size={20} /> : null}
                sx={{ backgroundColor: '#6666FF', marginBottom: '10px' }}
              >
                {loading ? 'Processing' : `Pay for ${numberOfLicenses} License(s)`}
              </Button>
            </Box>
          </>
      </Paper>
    </>
  )
}

export default PaymentForm
