/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useState } from 'react'
import { TEAMS_EXPORTER_API_BASE_URL } from '../../config'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAuth } from '../../contexts/authContext'

interface RemoveSubscriptionButtonProps {
  username: string
  onUsersUpdate: (updatedUsers: string[]) => void
}

const RemoveSubscriptionButton: React.FC<RemoveSubscriptionButtonProps> = ({ username, onUsersUpdate }) => {
  const { authenticatedRequest } = useAuth()
  const [loading, setLoading] = useState(false)

  const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    setLoading(true)
    event.stopPropagation()
    console.log('base username', { username })
    const encodedUsername = encodeURIComponent(username)
    console.log('encoded username', { encodedUsername })
    const response = await authenticatedRequest({
      method: 'delete',
      url: `${TEAMS_EXPORTER_API_BASE_URL}/admin/remove-subscription/${encodedUsername}`
    })
    setLoading(false)
    onUsersUpdate(response.data.filteredUsers)
  }

  return (
    <LoadingButton loading={loading} variant="outlined" color="error" onClick={handleButtonClick}>
      Revoke Premium Access
    </LoadingButton>
  )
}

export default RemoveSubscriptionButton
