import React from 'react'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

interface SearchBarProps {
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, setSearchTerm }) => (
  <TextField
    label="Search Chats or 'users: Alice, Bob' to filter by members"
    variant="outlined"
    fullWidth
    value={searchTerm}
    onChange={event => { setSearchTerm(event.target.value) }}
    style={{ marginBottom: '16px' }}
    InputProps={{
      endAdornment: searchTerm && (
        <IconButton
          size="small"
          onClick={() => { setSearchTerm('') }}
          edge="end"
        >
          <CloseIcon />
        </IconButton>
      )
    }}
  />
)

export default SearchBar
