import { Box, Menu, MenuItem, Typography, LinearProgress, Divider } from '@mui/material'
import React from 'react'
import { useAuth } from '../../contexts/authContext'
import { UserTier } from '../../types/UserTier'
import StarIcon from '@mui/icons-material/Star'
import { useNavigate } from 'react-router-dom'

const MAXIMUM_FREE_CHAT_EXPORTS = 5

interface UserMenuProps {
  isOpen: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
  onLogout: () => void
  onLogin: () => void
  isLoggedIn: boolean
  username: string
}

const calculatePercentageUsed = (isLoggedIn: boolean, numberOfChatsExported: number): number =>
  isLoggedIn ? (numberOfChatsExported / MAXIMUM_FREE_CHAT_EXPORTS) * 100 : 0

const generateMenuItems = (isAdmin: boolean, isLoggedIn: boolean, userTier: UserTier): Array<string | null> => [
  ...(isAdmin ? ['Admin'] : []),
  ...(isLoggedIn ? [userTier !== UserTier.PREMIUM ? 'Upgrade to Premium' : null, 'Logout'] : ['Login'])
].filter(Boolean)

export const UserMenu: React.FC<UserMenuProps> = ({ isOpen, anchorEl, onClose, onLogout, onLogin, isLoggedIn, username }) => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const isAdmin = JSON.parse(localStorage.getItem('isAdmin') ?? 'false')
  const percentageUsed = calculatePercentageUsed(isLoggedIn, currentUser.numberOfChatsExported)
  const menuItems = generateMenuItems(isAdmin, isLoggedIn, currentUser.userTier)

  const handleMenuItemClick = (item: string): void => {
    if (item === 'Logout') onLogout()
    else if (item === 'Login') onLogin()
    else if (item === 'Upgrade to Premium') navigate('/payment')
    else if (item === 'Admin') navigate('/admin')
    else onClose()
  }

  return (
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isOpen}
        onClose={onClose}
      >
        {isLoggedIn && (
      <MenuItem>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="subtitle1" color="textSecondary">Logged in as: {username}</Typography> {/* Grayed out text */}
          <Box display="flex" justifyContent="center" my={0.5}>
            <Divider style={{ width: '90%' }} />
          </Box>
          {currentUser.userTier === UserTier.PREMIUM && <StarIcon color="primary" sx={{ color: '#6666FF', marginLeft: 1, marginTop: 1 }} />}
          {currentUser.userTier !== UserTier.PREMIUM && (
            <>
              <Typography variant="body2">
                {currentUser.numberOfChatsExported} / {MAXIMUM_FREE_CHAT_EXPORTS} free chat exports used
              </Typography>
              <Box width="100%" mt={1} mb={1}>
                <LinearProgress variant="determinate" value={percentageUsed} sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#6666FF'
                  }
                }} />
              </Box>
            </>
          )}
        </Box>
      </MenuItem>
        )}
    <Box display="flex" justifyContent="center" my={1}>
      <Divider style={{ width: '90%' }} />
    </Box>
    {menuItems.map((item: any) => (
      item
        ? (
          <MenuItem key={item} onClick={() => { handleMenuItemClick(item) }}>
            <Typography textAlign="center">{item}</Typography>
            {item === 'Upgrade to Premium' && <StarIcon color="primary" sx={{ color: '#6666FF', marginLeft: 1 }} />}
          </MenuItem>
          )
        : null
    ))}
  </Menu>
  )
}
