/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from 'react'
import { Button, Container, Typography, Box, Paper } from '@mui/material'
import { Microsoft } from '@mui/icons-material'
import { useAzureLogin } from '../../hooks/useAzureLogin'

const LoginPrmopt: React.FC = () => {
  const { handleLogin } = useAzureLogin()

  return (
        <Container
            maxWidth="xs"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center'
            }}
        >
            <Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center', borderRadius: '1rem' }}>
                <Typography variant="h5" gutterBottom>
                    Welcome to Teams Exporter
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Please continue with your Microsoft account.
                </Typography>
                <Box mt={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Microsoft />}
                        onClick={handleLogin}
                    >
                        Continue with Microsoft
                    </Button>
                </Box>
            </Paper>
        </Container>
  )
}

export default LoginPrmopt
